<template lang="pug">
div(
  :class="{ 'angle-close': active && show && collapsible, 'angle-open': active && !show && collapsible, 'border-top-h3': borderTop }"
)
  .h3-elem.d-flex.align-items-center(
    @click="toggle"
    :style="{ cursor: collapsible && 'pointer', paddingRight: collapsible && '4rem', justifyContent: 'space-between' }"
  )
    .label(
      :style="{ fontWeight: bold ? 'bold' : 'normal', fontSize: bold ? '0.9375rem' : '0.8125rem' }"
    ) {{ $t(title) }}
      slot(name="tooltip")
    .om-switches-wrapper
      vue-switches.switch-position.m-0.ml-3(
        v-if="switchable"
        :value="initial"
        @click.native="valueSet"
        theme="om-orange"
        type-bold="true"
      )
  transition(v-if="!!$slots.default" name="expand")
    .expand-transition.my-2(v-if="(!collapsible && active) || show")
      slot
</template>

<script>
  import { mapGetters, mapState, mapMutations } from 'vuex';
  import accordionMixin from '@/editor/mixins/sidebar/accordion';

  export default {
    mixins: [accordionMixin],
    props: {
      borderTop: { type: Boolean, default: true },
      bold: { type: Boolean, default: true },
      toggleProperty: { type: String, required: true },
      collapsible: { type: Boolean, default: true },
      switchable: { type: Boolean, default: true },
      initShow: { type: Boolean, default: false },
      title: { type: String, default: '' },
      openOnActivate: { type: Boolean, default: false },
    },
    data: () => ({
      show: false,
    }),
    computed: {
      ...mapState(['selectedElement']),
      ...mapGetters(['nestedAccess']),
      active() {
        return this.nestedAccess(this.toggleProperty) || false;
      },
      initial() {
        const active = this.nestedAccess(this.toggleProperty) || false;
        if (active && this.initShow) {
          // eslint-disable-next-line
          this.show = true;
        } else {
          // eslint-disable-next-line
          this.show = false;
        }
        return active;
      },
    },
    watch: {
      active(n) {
        if (!n) this.show = false;
      },
    },
    methods: {
      ...mapMutations(['updateData']),
      valueSet(e) {
        if (!this.openOnActivate) e.stopPropagation();
        const value = !this.initial;
        this.updateData({ property: this.toggleProperty, value });
        window.om.bus.$emit('historySave');
      },
      toggle() {
        if (this.active && this.show) {
          this.close();
          return;
        }

        if (!this.active) return;

        this.$bus.$emit('toggleAccordion', { id: this._uid, canRemainOpen: false });
      },
    },
  };
</script>
